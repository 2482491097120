*{

	padding: 0;
	margin: 0;
	box-sizing: border-box;
	--burger-color: #19abe7;
	--text-color: #19abe7;
	--stressed-color: #3a3799;
}


.mainNav{
	display: inline-block;
	
}


.mainMenu{
	
	list-style: none;
	display: flex;
	flex-direction: column;
	margin-left: 2em;
	margin-right: 2em;
	
	
	
	
}

.menuItem{
	
	display: none;
}



#hamburger{
	display: flex;
	float: right;
	/*color: var(--burger-color);*/
	color: white;
	
}

.mainMenu ul{
	margin: 0;
	padding: 0;
}


.mainMenu li{
	
	padding: 1em;
	font-family: OpenSansSemiBold;

}

.mainMenu li a{
	/*color: var(--text-color);*/
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	
}

.mainMenu li a:hover,
.mainMenu li a:focus{
	
	text-decoration: underline;
	
	
}



.menuItem a{

	align-self: center;
}


.displayMenu{
	display: flex;
	

}



@media screen and (min-width: 760px){


	.mainMenu{
			display: flex;
			flex-direction: row;
			

		}
	.menuItem{
		display: block;
	
		align-self: flex-end;

	}
	
	#hamburger{
		display: none;
	}

	
}